<template>
  <div class="page-main-allocation">
    <b-card>
      <div class="title-allocation">
          <h4>{{isAllocation? 'Cập nhật' : 'Tạo mới'}} cấp phát vật dụng</h4>
      </div>
      <validation-observer ref="modelAllocationEmployee">
        <div class="form-allocation mt-2">
          <div class="form-allocation">
            <b-row>
              <b-col lg="3" md="3" xs="12">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="departmentId" rules="required">
                    <label class="font-size-label-custom font-weight-bold">Phòng ban <span class="text-danger">(*)</span></label>
                    <v-select disabled label="organizationBranchName" @input="getListEmployeeByDepartment" v-model="modelAllocationEmployee.organizationBranchId" :reduce="(department) => department.id" :options="listDepartment"></v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="form-allocation">
            <b-row>
              <b-col lg="3" md="3" xs="12">
                <b-form-group>
                    <label class="font-size-label-custom font-weight-bold">Vật dụng</label>
                    <b-form-input readonly v-model="modelAllocationEmployee.deviceString"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" xs="12">
                <b-form-group v-if="!isAllocation">
                  <validation-provider #default="{ errors }" name="employeeId" rules="required">
                    <label class="font-size-label-custom font-weight-bold">Nhân viên <span class="text-danger">(*)</span></label>
                    <v-select v-model="modelAllocationEmployee.employeeId" :reduce="(idEmployee) => idEmployee.id" label="fullName" :options="listEmployee">
                    <span slot="no-options" @click="$refs.select.open = false">
                      Không có dữ liệu
                    </span>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="isAllocation">
                    <label class="font-size-label-custom font-weight-bold">Nhân viên <feather-icon style="color: #059862" v-b-tooltip.hover.top="'Chọn phòng ban để lấy danh sách nhân viên'" icon="HelpCircleIcon"></feather-icon> <span class="text-danger">(*)</span></label>
                    <v-select disabled v-model="modelAllocationEmployee.employeeId" :reduce="(idEmployee) => idEmployee.id" label="fullName" :options="listEmployee">
                    <span slot="no-options" @click="$refs.select.open = false">
                      Không có dữ liệu
                    </span>
                    </v-select>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" xs="12">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="assignDate" rules="required">
                    <label class="font-size-label-custom font-weight-bold">Ngày cấp <span class="text-danger">(*)</span></label>
                    <flat-pickr v-model="modelAllocationEmployee.assignDate"
                                class="form-control"
                                :config="configFlatpickr"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" xs="12">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="quantityAllocation" rules="required|integer">
                    <label class="font-size-label-custom font-weight-bold">Số lượng <span class="text-danger">(*)</span></label>
                    <b-form-input v-model="modelAllocationEmployee.quantity"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="form-allocation">
            <b-row>
              <b-col lg="12" md="12" sm="12" xs="12">
                <b-form-group>
                  <label class="font-size-label-custom font-weight-bold">Lý do</label>
                  <b-form-textarea v-model="modelAllocationEmployee.reason"></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="form-allocation">
            <b-row>
              <b-col lg="12" md="12" sm="12" xs="12">
                <b-form-group>
                  <label class="font-size-label-custom font-weight-bold">Thông tin bổ sung</label>
                  <b-form-textarea v-model="modelAllocationEmployee.metadata"></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="acction-allocation">
          <b-button v-if="!isAllocation"  class="mr-1" @click="addAllocation"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
          >
            <feather-icon
                icon="CheckIcon"
                class="mr-50"
            />
            <span class="align-middle">Tạo mới</span>
          </b-button>
          <b-button v-if="isAllocation" class="mr-1" @click="updateAllocationEmployee"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
          >
            <feather-icon
                icon="CheckIcon"
                class="mr-50"
            />
            <span class="align-middle">Cập nhật</span>
          </b-button>
          <b-button v-if="!isAllocation" :to="{ name: 'manager-item'}"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
          >
            <feather-icon
                icon="CornerDownLeftIcon"
                class="mr-50"
            />
            <span class="align-middle">Quay lại</span>
          </b-button>
          <b-button v-if="isAllocation" :to="{ name: 'allocation-recall-device'}"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
          >
            <feather-icon
                icon="CornerDownLeftIcon"
                class="mr-50"
            />
            <span class="align-middle">Quay lại</span>
          </b-button>
        </div>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BButton,
  VBTooltip, BFormGroup, BRow, BCol, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import useJwt from '@/auth/jwt/useJwt'
import flatPickr from 'vue-flatpickr-component'
import * as moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      listDepartment: [],
      isAllocation: false,
      listDevice: [],
      listEmployee: [],
      idAllocation: null,
      required,
      isEmployee: false,
      modelAllocationEmployee: {
        employeeString: '',
        employeeId: null,
        deviceString: '',
        quantity: null,
        deviceId: null,
        assignDate: '',
        metadata: '',
        reason: '',
        organizationBranchId: null,
      },
      configFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
    }
  },
  methods: {
    getListDepartment() {
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
      })
    },
    getListEmployeeByDepartment() {
      if (this.modelAllocationEmployee.organizationBranchId === null) {
        this.listEmployee = []
      } else {
        this.$crm.post('employee-school/all', this.modelAllocationEmployee).then(res => {
          this.listEmployee = res.data
        })
      }
    },
    addAllocation() {
      this.$refs.modelAllocationEmployee.validate().then(success => {
        if (success) {
          if (this.modelAllocationEmployee.quantity) {
            this.modelAllocationEmployee.quantity = this.modelAllocationEmployee.quantity * 1
          }
          if (this.modelAllocationEmployee.assignDate) {
            this.modelAllocationEmployee.assignDate = moment(this.modelAllocationEmployee.assignDate).format('yyyy-MM-DDT00:00:00')
          }
          this.$crm.post('device/assign', [this.modelAllocationEmployee]).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: `Cấp phát vật dụng ${this.modelAllocationEmployee.deviceString} thành công`,
              },
            })
            this.$router.push({
              name: 'allocation-recall-device',
            })
          })
        }
      })
    },
    getDetailAllocationEmployee() {
      this.$crm.post(`device/find-assigned-device-by-id/${this.idAllocation}`).then(res => {
        this.modelAllocationEmployee = res.data[0]
        this.modelAllocationEmployee.employeeId = parseInt(this.modelAllocationEmployee.employeeId)
        this.modelAllocationEmployee.deviceString = this.modelAllocationEmployee.type
        this.getListEmployeeByDepartment()
      })
    },
    updateAllocationEmployee() {
      this.$refs.modelAllocationEmployee.validate().then(success => {
        if (success) {
          this.$crm.post(`device/update-equipment-device/${this.idAllocation}`, this.modelAllocationEmployee).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: `Cập nhật cấp phát vật dụng cho nhân viên ${this.modelAllocationEmployee.fullName} thành công`,
              },
            })
            this.$router.push({
              name: 'allocation-recall-device',
            })
          })
        }
      })
    },
  },
  created() {
    const parameters = this.$route.query
    this.modelAllocationEmployee.deviceString = parameters.nameDevice
    this.modelAllocationEmployee.deviceId = parameters.id
    this.idAllocation = parameters.idAllocation
    this.modelAllocationEmployee.organizationBranchId = parameters.organizationBranchId
    if (this.modelAllocationEmployee.organizationBranchId) {
      this.getListEmployeeByDepartment()
    }
    if (this.idAllocation) {
      this.isAllocation = true
      this.getDetailAllocationEmployee()
    }
    this.getListDepartment()
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/custom/allocation';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
